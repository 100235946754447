@media (min-width: 641px){
	img.hero-media-image {
		max-width: 80%;
	}
}
img.hero-media-image {
	max-width: 80%;
}
.button-primary{
	background-color:#f6c123;
	border-radius: 25px;
}
.button-primary:hover {
    background: #e0b233;
}
.button-alternative{
	background-color:#E91E63;
	color:white !important;
	border-radius: 25px;
}
.button-alternative:hover {
    background: #b2174a;
}

.lights-off .body-wrap {
	background: #000;
}


/**CUSTOM CSS**/
.hero{
	padding-top: 88px;
	padding-bottom: 88px;
}
.hero-cta{
	margin: 0 auto;
}
.hero-inner{
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	align-items: center;

}
@media(min-width:760px){
	.hero-inner{
		flex-direction: row;
	}
	.features-image{
		margin-left: 30px;
	}
}
@media(max-width:760px){
	.hero-copy{
		padding:0;
	}
}

.btn-video{
	color:#fff;
	border:1px solid #fff;
	background: #000;
	padding:5px 10px;
}
.promotions{
	display: flex;
	flex-direction: column;
	padding-bottom:48px;
}
.promotions-div{
	display: flex;
	flex-direction: column;
	margin: 10px 0;
	height: 196px;
	justify-content: space-between;
}
@media(min-width:790px){
	.promotions{
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}
	.promotions-div{
		margin: 0 10px;
	width:220px;

	}
}

.promotions-div.promotions-mensual{

}
.promotions-div.promotions-semestral{

}
.promotions-div.promotions-anual{

}
.promotions-title{
 text-align: center;
 text-transform: uppercase;
 font-size: 30px;
}
.promotions-text{
 text-align: center;
}
.promotions-price{
 text-align: center;
 color:#fff;
 font-size:24px;
}


.description{
	padding: 48px 0;
}
.description-text{
	color:#fff;
	margin:0;
	text-align: center;
	font-size: 20px;
	margin-bottom: 4px;
}
@media(min-width:760px){
	.description-text{
		font-size: 24px;
		margin-bottom: 8px;
	}
}

.description-text-mark{
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 16px;
}
@media(min-width:760px){
	.description-text-mark{
		font-size: 30px;
		margin-bottom: 20px;
	}
}
.create-mix{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.create-mix-button{
	margin-bottom: 24px;

}
.create-mix-text{
	color:#E91E63;

}
