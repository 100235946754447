.modal{
 width: 100vw;
 height: 100vh;
 position: fixed;
 z-index: 9999;
 top:0;
 left:0;
 animation: opacity 0.3s ease-in;
}
.modal-overlay{
	background: rgba(0,0,0,0.7);
	width: 100%;
	height: 100%;
	position: absolute;
	filter: blur(40px);
}
.modal-content{
	padding: 70px 100px;
	width: 100%;
	height: 100%;
	z-index: 999999;
	position: relative;
}
.modal-close{
	width: 45px;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	color:#fff;
	font-weight: bold;
	text-align: center;
	top: 50px;
	right: 80px;
	position: absolute;
	background: transparent;
	border: none;
	border-radius: 50%;
	padding: 10px;
	z-index:99999999;

}
.modal-close:hover{
	background-color: rgba(39, 39, 39,1);
}

@keyframes opacity{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
